<template>
  <div class="parent">
    <img
      ref="img"
      class="product_img"
      :src="productImageUrl"
      :alt="`${productName} Logo`"
      @load="setImgBackground()"
    />
    <img
      v-if="discounted"
      class="discounted"
      :alt="`discounted ${productName}`"
      src="../assets/aktion.png"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  productImageUrl: {
    type: String,
    required: true,
  },
  productName: {
    type: String,
    required: true,
  },
  productColor: {
    type: String,
    required: true,
  },
  hasMoney: {
    type: Boolean,
    required: true,
  },
  discounted: {
    type: Boolean,
    required: true,
  },
});

const isLoaded = ref(false);
const img = ref(null);

const setImgBackground = () => {
  if (props.hasMoney) {
    img.value.style.setProperty("filter", `drop-shadow(0px 0px 10px ${props.productColor})`);
  }
};

watch(
  () => props.productImageUrl,
  (image) => {
    if (img.value) {
      isLoaded.value = false;

      img.value.onload = () => {
        isLoaded.value = true;
      };
      img.value.src = image;
    }
  },
  { immediate: true }
);

watch(
  () => props.hasMoney,
  (money) => {
    if (!money) {
      img.value.style.setProperty("filter", "");
    } else {
      img.value.style.setProperty("filter", `drop-shadow(0px 0px 10px ${props.productColor})`);
    }
  }
);

defineExpose({ img });
</script>

<style scoped>
.product_img {
  top: 0;
  left: 0;
  position: relative;
  max-width: 70%;
  margin: auto;
  z-index: 997;
}

.parent {
  top: 0;
  left: 0;
  position: relative;
  display: inline-block;
  text-align: center;
}

.discounted {
  position: absolute;
  user-select: none;
  left: 20%;
  top: 40%;
  width: 100px;
  z-index: 998;
  rotate: -15deg;
}
</style>