<template>
  <div class="backgroundImage" :style="{ 'background-image': `url(${picture})` }">
    <div v-if="loading" class="loading"><div class="loader" /></div>
    <div v-else class="container">
      <span
        >Last Ping:
        {{
          lastPing.getHours() +
          ":" +
          ("00" + lastPing.getMinutes()).slice(-2) +
          ":" +
          ("00" + lastPing.getSeconds()).slice(-2)
        }}</span
      >
      <div class="lockBackground">
        <img class="lock" src="../assets/lock.svg" alt="Logo Graphic" /><br />
      </div>
    </div>
  </div>
</template>

<script setup>
import { createApi } from "unsplash-js";
import { onMounted, ref } from "vue";

defineProps({
  loading: Boolean,
  lastPing: Date,
});

const picture = ref("");

// We're running Unsplash without a Proxy, as we can consider the key not public on the Matekiosk
const unsplashServerAPI = createApi({
  accessKey: "e80bfd030fa0bfb0ad4b0656d1ad7e5739312e6758c3ffaf6a93895e5c0a8f15",
});
const func = () => {
  const categories = ["tea", "cocktail", "lemonade", "mate tea", "spritzer"];
  const index = Math.floor(Math.random() * categories.length);
  unsplashServerAPI.photos
    .getRandom({
      query: categories[index],
      orientation: "landscape",
      featured: true,
    })
    .then((json) => {
      picture.value = `${json.response.urls.raw}&w=${window.innerWidth}&h=${window.innerHeight}&fit=crop`;
    });
};

onMounted(() => {
  func();
  window.setInterval(func, 3600000);
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
}
.lockBackground {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lock {
  height: 20%;
}
.loading {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
}
.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #808080;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
