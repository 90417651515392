import { createApp, provide, h } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";

import App from "./App.vue";
import NoBearer from "./components/NoBearer.vue";
import apollo from "./apolloClient";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apollo);
  },

  render: () => {
    if (params.bearer) {
      return h(App);
    }
    return h(NoBearer);
  },
});

app.mount("#app");
