<template>
  <div class="grid">
    <div class="keyboard">
      <div
        v-for="index in 9"
        :key="index"
        :class="{ disabled: maxAmountReached }"
        class="button"
        @click="addAndShift(index)"
      >
        <span class="buttonText">{{ index }}</span>
      </div>
      <div class="button" :class="{ disabled: amount == 0 }" @click="invert">
        <span class="buttonText">-</span>
      </div>
      <div class="button" :class="{ disabled: maxAmountReached }" @click="addAndShift(0)">
        <span class="buttonText">0</span>
      </div>
      <div class="button" :class="{ disabled: amount == 0 }" @click="unshift">
        <span class="buttonText">&larr;</span>
      </div>
    </div>
    <div class="amountBox">
      <div class="amountPad">
        <span class="amountText">{{ `${(Number(amount) / 100).toFixed(2)}€` }}</span>
      </div>
      <div class="enterButton">
        <div class="enterPad">
          <div class="button" @click="$emit('finalizedInput', Number(amount))">
            <span class="enterButtonText">
              {{ enterButtonText }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* global BigInt */
import { computed, ref } from "vue";

const amount = ref(BigInt(0));

const invert = () => {
  amount.value *= BigInt(-1);
};
const unshift = () => {
  amount.value /= BigInt(10);
};
const addAndShift = (toAdd) => {
  amount.value *= BigInt(10);
  amount.value += BigInt(amount.value >= BigInt(0) ? toAdd : toAdd * -1);
};

const maxAmountReached = computed(() => Math.abs(Number(amount.value)) >= 10000);

const enterButtonText = computed(() => {
  switch (true) {
    case amount.value < 0:
      return "Entladen";
    case amount.value > 0:
      return "Aufladen";
    default:
      return "Abbrechen";
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grid {
  display: grid;
  grid-template-columns: 100vh calc(100vw - 100vh);
}
.keyboard {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
.button {
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  border: 0.5px solid gray;
  color: black;
}
.amountBox {
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}
.amountText {
  font-size: xxx-large;
  width: 100%;
  text-align: center;
}
.amountPad {
  display: flex;
  align-items: flex-end;
}
.buttonText {
  font-size: xxx-large;
  margin: auto;
}
.enterButton {
  display: flex;
  align-items: flex-end;
}
.enterPad {
  width: 100%;
}
.enterButtonText {
  font-size: xxx-large;
  margin: 10px auto;
  color: black;
}
.disabled {
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  color: grey;
}
</style>
