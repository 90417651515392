<template>
  <div class="buttonWrapper">
    <div class="buttonGrid">
      <div class="settingButton" @click="reload">
        <AutorenewIcon :size="150" />
        <span>Neu laden</span>
      </div>
      <div class="settingButton" @click="$emit('close')">
        <CloseCircleOutlineIcon :size="150" />
        <span>Schließen</span>
      </div>
    </div>
    <div class="uuidWrapper">
      <span class="uuid">{{ uuid }}</span>
    </div>
  </div>
</template>

<script setup>
import AutorenewIcon from "vue-material-design-icons/Autorenew.vue";
import CloseCircleOutlineIcon from "vue-material-design-icons/CloseCircleOutline.vue";

const reload = () => window.location.reload();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.buttonWrapper {
  display: grid;
  grid-template-columns: 100vh calc(100vw - 100vh);
}
.buttonGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.settingButton {
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  border: 0.5px solid gray;
  color: black;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: x-large;
}
.uuidWrapper {
  display: flex;
  align-items: flex-end;
}
.uuid {
  margin: 0 auto;
  font-size: large;
}
</style>
