<template>
  <div>
    <div id="touch" class="popup"></div>
    <Idle v-show="idle" :loading="loading" :last-ping="lastPing"></Idle>
    <div v-show="!idle">
      <Message
        v-show="message"
        :header="messageHeader"
        :message="messageText"
        :button-text="buttonText"
        class="message"
        @buttonPressed="messageButtonPressed"
      />
      <div v-if="loading" class="overlay"></div>
      <Keyboard v-else-if="topupcash" class="overlay" @finalizedInput="topupCash" />
      <Setting
        v-else-if="setting"
        :uuid="1234"
        class="overlay"
        @delete="deleteTag"
        @clone="cloneTag"
        @close="setting = false"
      />
      <NewSmartcard v-if="!user && cardId" :new-smartcard="cardId" class="message" />
      <div v-if="user !== null" class="wrapper">
        <div
          v-if="offeringsResult && offeringsResult.offerings && soldLastFourWeeksResult"
          :style="{
            'grid-template-columns': `repeat(${numberPerRow}, 1fr)`,
            'grid-template-rows': `repeat(${numberPerRow}, 1fr)`,
          }"
          class="offerings"
        >
          <div v-for="(product, index) in sortedFilteredProducts" :key="index">
            <div
              v-if="!user.isAdmin && -1 * userBalance < product.priceCents"
              :style="{ height: `${productHeight}px`, width: `${productHeight}px` }"
              class="buttonOverlay"
            ></div>
            <div
              :style="{ height: `${productHeight}px` }"
              class="product infinite"
              @click="clickHandler(product, index)"
            >
              <MateImage
                :id="'Product ' + index"
                :product-image-url="product.imageUrl"
                :product-name="product.readableName"
                :product-color="product.color"
                :has-money="user.isAdmin || -1 * userBalance >= product.priceCents"
                :discounted="product.discounted"
              />
              <span
                :style="{
                  'margin-top': `${productHeight / -20}px`,
                  'margin-bottom': `${productHeight / 20}px`,
                }"
              >
                {{ `${(product.priceCents / 100).toFixed(2).toString().replace(".", ",")}€` }}
              </span>
            </div>
          </div>
          <div
            class="product"
            :style="{ 'grid-column': numberPerRow - 1, 'grid-row': numberPerRow }"
            @click="topupcash = true"
          >
            <img src="../assets/currency-eur.png" class="product_img" alt="Euro Icon" />
          </div>
          <div
            class="product"
            :style="{ 'grid-column': numberPerRow, 'grid-row': numberPerRow }"
            @click="setting = true"
          >
            <img src="../assets/gear.png" class="product_img" alt="Gear Icon" />
          </div>
        </div>
        <div class="info">
          <div class="qrcode">
            <div
              v-if="boughtProducts.length"
              :style="{ height: `${(numberPerRow - 1) * productHeight}px` }"
              class="boughtProducts"
            >
              <div
                v-for="(bought, index) in boughtProducts"
                :key="`bought${index}`"
                class="boughtProduct"
              >
                {{
                  `${bought.readableName} ${(bought.priceCents / -100)
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")}€`
                }}
              </div>
            </div>
            <img
              v-else
              :src="require('../assets/icon.png')"
              :height="(numberPerRow - 1) * productHeight"
              alt="Matemate Logo"
            />
          </div>
          <div :height="productHeight">
            <div class="balance-dispo-wrapper">
              <div class="balance">
                {{ `${(userBalance / -100).toFixed(2).toString().replace(".", ",")}€` }}
              </div>
              <div class="dispo">
                {{ user.fullName }}
                {{ user.isAdmin ? "(Admin)" : "" }}
              </div>
              <div v-if="user.username === 'maximilianh' || user.username === 'maxb'" class="dispo">
                Matefachverkauf a.D.
              </div>
            </div>
            <div v-if="boughtProducts.length" class="undo" @click="undo()">Rückgängig</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import gql from "graphql-tag";
import "@fontsource/inter";
import { computed, ref, onMounted } from "vue";
import { useLazyQuery, useMutation, useQuery } from "@vue/apollo-composable";
import { animate } from "motion";
import Idle from "./Idle.vue";
import Setting from "./Setting.vue";
import Keyboard from "./Keyboard.vue";
import Message from "./Message.vue";
import NewSmartcard from "./NewSmartcard.vue";
import MateImage from "./MateImage.vue";

// For now, hard-code Admin SmartCards
const ADMIN_SMARTCARDS = [
  // Gero
  "semesterticket_043824da2c54",
  "ubi_27b90d3c010db9",
  "semesterticket_04804a422e6e",
  // Fredi
  "semesterticket_041b46ea2d6e",
  // Lukas
  "blanks_94bbb5d5",
  "blanks_5dad60b3",
  "semesterticket_0407143a5b57",
  "semesterticket_0470320a2e6e",
  // Domdom
  "semesterticket_04174f32e958",
];

const USER_FROM_SMARTCARD = gql`
  query user($card_id: String!) {
    userFromSmartcard(smartcardId: $card_id) {
      fullName
      bluecardId
      balance
      username
      isAdmin
    }
  }
`;

const MUTATION_PURCHASE_PRODUCT = gql`
  mutation PurchaseProduct($payer: String!, $product: String!) {
    purchase(payer: $payer, product: $product) {
      id
    }
  }
`;

const MUTATION_UNDO_PURCHASE = gql`
  mutation UndoPurchase($transactionId: Int!) {
    undoPurchase(transactionId: $transactionId)
  }
`;

const MUTATION_TOPUP = gql`
  mutation Topup($payer: String!, $amountCents: Int!) {
    topup(payer: $payer, amountCents: $amountCents) {
      id
    }
  }
`;

const SOLD_LAST_FOUR_WEEKS = gql`
  query SoldLastFourWeeks {
    stats {
      soldLastFourWeeks {
        offeringId
        qty
      }
    }
  }
`;

const boughtProducts = ref([]);
const height = ref(window.innerHeight);
const width = ref(window.innerWidth);
const cardId = ref(null);
const idle = ref(true);
const setting = ref(false);
const topupcash = ref(false);
const message = ref(false);
const messageHeader = ref("");
const messageText = ref("");
const buttonText = ref("");
const webSocket = ref(null);
const logOut = ref(false);
const lastPing = ref(new Date());

const cardIdQuery = computed(() => ({ card_id: cardId.value }));

const {
  result: userResult,
  loading: loadingUser,
  onResult: onResultUser,
  refetch: refetchUser,
  load: loadUser,
} = useLazyQuery(USER_FROM_SMARTCARD, cardIdQuery, {
  fetchPolicy: "no-cache",
});

const {
  result: soldLastFourWeeksResult,
  loading: loadingSoldLastFourWeeks,
  load: loadSoldLastFourWeeks,
} = useLazyQuery(SOLD_LAST_FOUR_WEEKS, {
  fetchPolicy: "no-cache",
});

const user = computed(() => userResult.value?.userFromSmartcard ?? null);

onResultUser(() => {
  // Only set idling to false if the user has not removed the card yet
  if (cardId.value != null) {
    idle.value = false;
  }
});

const { mutate: purchaseProduct, loading: purchasingProduct } = useMutation(
  MUTATION_PURCHASE_PRODUCT,
  {
    fetchPolicy: "no-cache",
  }
);

const { mutate: undoPurchase, loading: undoingPurchase } = useMutation(MUTATION_UNDO_PURCHASE, {
  fetchPolicy: "no-cache",
});

const { mutate: topup, loading: toppingUp } = useMutation(MUTATION_TOPUP, {
  fetchPolicy: "no-cache",
});

const {
  result: offeringsResult,
  loading: loadingOfferings,
  onResult: onResultOfferings,
  refetch: refetchOfferings,
} = useQuery(
  gql`
    query offerings {
      offerings {
        name
        readableName
        priceCents
        imageUrl
        inInventory
        color
        discounted
      }
    }
  `,
  null,
  {
    fetchPolicy: "no-cache",
  }
);
const offerings = computed(() =>
  (offeringsResult.value?.offerings ?? []).filter((product) => product.inInventory > 0)
);

onResultOfferings(() =>
  (offeringsResult.value?.offerings ?? []).filter((product) => product.inInventory > 0)
);

// { [key: string]: number }
const offeringRanking = computed(() =>
  soldLastFourWeeksResult.value.stats.soldLastFourWeeks.reduce(
    (prev, cur) => ({ ...prev, [cur.offeringId]: cur.qty }),
    {}
  )
);

const sortedFilteredProducts = computed(() =>
  (offeringsResult.value?.offerings ?? [])
    .filter((product) => product.inInventory > 0)
    .toSorted((a, b) => offeringRanking.value[b.name] - offeringRanking.value[a.name])
);

const defaultOnMessage = (event) => {
  const wsMessage = JSON.parse(event.data);
  if (wsMessage.event === "CardRemoved") {
    idle.value = true;
    cardId.value = null;
  } else if (wsMessage.event === "KeepAlive") {
    lastPing.value = new Date();
  } else if (Object.prototype.hasOwnProperty.call(wsMessage.event, "CardPushed")) {
    message.value = false;
    logOut.value = false;
    setting.value = false;
    topupcash.value = false;
    boughtProducts.value = [];

    cardId.value = wsMessage.event.CardPushed;

    refetchUser();
    loadUser();

    refetchOfferings();
  } else {
    console.error("Unknown Event", { event });
  }
};

const createWebsocket = () => {
  // Hack to stop onclose chain
  if (webSocket.value && webSocket.value.readyState === WebSocket.OPEN) {
    return;
  }
  webSocket.value = new WebSocket("ws://127.0.0.1:9001");
  webSocket.value.onclose = setTimeout(() => createWebsocket(), 5000);
  webSocket.value.onmessage = (event) => defaultOnMessage(event);
};

// +2 because of topup button & settings button
const numberPerRow = computed(() => Math.ceil(Math.sqrt(offerings.value.length + 2)));
const productHeight = computed(() => height.value / numberPerRow.value);

const userBalance = computed(
  () =>
    (user?.value.balance ?? 0) +
    boughtProducts.value.reduce((prev, curr) => prev + curr.priceCents, 0)
);

createWebsocket();

onMounted(() => {
  loadSoldLastFourWeeks();
  window.addEventListener("resize", () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  });
});
const messageButtonPressed = () => {
  messageText.value = "";
  messageHeader.value = "";
  message.value = false;

  webSocket.value.onmessage = (event) => defaultOnMessage(event);
  if (logOut.value) {
    idle.value = true;
    cardId.value = "";
  }
  buttonText.value = "";
};

const buyProduct = (product) => {
  purchaseProduct({
    payer: user.value.username,
    product: product.name,
  })
    .then((data) => {
      boughtProducts.value.push({ ...product, purchaseId: data.data.purchase.id });
      refetchOfferings();
    })
    .catch(() => {
      messageHeader.value = "Was ist hier passiert?";
      messageText.value =
        "Die Zahlung konnte nicht verarbeitet werden. Bitte nochmal versuchen oder Max informieren";
      buttonText.value = "OK";
      message.value = true;
    });
};

const clickHandler = (product, index) => {
  animate(
    document.getElementById(`Product ${index}`),
    { transform: ["scale(1.2)", "rotate(30deg)", "rotate(-30deg)", "rotate(0deg)", "scale(1)"] },
    { duration: 2, easing: "ease-out" }
  );
  buyProduct(product);
};

const undo = () => {
  const len = boughtProducts.value.length;
  if (len > 0) {
    const toUndo = boughtProducts.value[len - 1];
    undoPurchase({
      transactionId: toUndo.purchaseId,
    })
      .then(() => {
        boughtProducts.value.pop();
      })
      .catch(() => {
        messageHeader.value = "Was ist hier passiert?";
        messageText.value =
          "Das Rückgängigmachen konnte nicht verarbeitet werden. Bitte nochmal versuchen oder Max informieren";
        buttonText.value = "OK";
        message.value = true;
      });
  }
};

const topupCash = (amountCents) => {
  topupcash.value = false;
  if (amountCents === 0) {
    return;
  }
  const currUser = user;
  if (user.value.isAdmin || amountCents < 0) {
    topup({
      payer: user.value.username,
      amountCents,
    })
      .then((data) => {
        boughtProducts.value.push({
          readableName: "Top-Up",
          priceCents: -amountCents,
          purchaseId: data.data.topup.id,
        });
      })
      .catch(() => {
        messageHeader.value = "Was ist hier passiert?";
        messageText.value =
          "Das Aufladen konnte nicht verarbeitet werden. Bitte nochmal versuchen oder Max informieren";
        buttonText.value = "OK";
        message.value = true;
      });
  } else {
    messageHeader.value = "Aufladung durch Admin autorisieren";
    messageText.value = `Aufladung i.H.v. ${(amountCents / 100)
      .toFixed(2)
      .toString()
      .replace(".", ",")}€ auf Konto von ${currUser.value.username} bestätigen?`;
    buttonText.value = "Abbrechen";
    message.value = true;
    webSocket.value.onmessage = (event) => {
      const wsMessage = JSON.parse(event.data);
      if (Object.prototype.hasOwnProperty.call(wsMessage.event, "CardPushed")) {
        if (ADMIN_SMARTCARDS.includes(wsMessage.event.CardPushed)) {
          topup({
            payer: currUser.value.username,
            amountCents,
          })
            .then((data) => {
              boughtProducts.value.push({
                readableName: "Top-Up",
                priceCents: -amountCents,
                purchaseId: data.data.topup.id,
              });
            })
            .catch(() => {
              messageHeader.value = "Was ist hier passiert?";
              messageText.value =
                "Das Aufladen konnte nicht verarbeitet werden. Bitte nochmal versuchen oder Max informieren";
              buttonText.value = "OK";
              message.value = true;
            });

          messageButtonPressed();
        }
      }
    };
  }
};

const loading = computed(
  () =>
    loadingUser.value ||
    loadingOfferings.value ||
    purchasingProduct.value ||
    undoingPurchase.value ||
    toppingUp.value ||
    loadingSoldLastFourWeeks.value
);

document.addEventListener("click", async (event) => {
  const popup = document.getElementById("touch");

  popup.style.left = `${event.clientX}px`;
  popup.style.top = `${event.clientY}px`;
  popup.style.visibility = "visible";
  popup.style.opacity = 0;
});

document.addEventListener("transitionend", () => {
  const popup = document.getElementById("touch");

  popup.style.visibility = "hidden";
  popup.style.opacity = 1;
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
html {
  font-family: "Inter", sans-serif;
  cursor: none;
}

* {
  overflow: hidden;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

.out_of_stock {
  color: tomato;
}

body {
  margin: 0;
  color: white;
}

.product_img {
  max-width: 70%;
  margin: auto;
}

.wrapper {
  display: grid;
  grid-template-columns: 100vh calc(100vw - 100vh);
}

.offerings {
  display: grid;
  background-color: black;
}

.product {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}

.qrcode {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.info {
  background-color: #aaa;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.buttonOverlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.balance {
  background-color: #444;
  text-align: center;
  font-size: x-large;
  margin-top: 10px;
  padding: 5px;
}

.undo {
  background-color: red;
  text-align: center;
  font-size: large;
  margin-top: 5px;
  padding: 5px;
  height: 50%;
  max-height: 50%;
}

.dispo {
  background-color: #444;
  text-align: center;
  font-size: medium;
}

.balance-dispo-wrapper {
  height: 50%;
  max-height: 50%;
}

.group {
  background-color: #444;
  text-align: center;
  font-size: x-large;
  margin-top: 30px;
  padding: 3px;
}

.boughtProducts {
  align-self: start;
  padding-left: 20px;
}

.boughtProduct {
  color: black;
  font-size: xx-large;
}

.message {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.popup {
  position: absolute;
  visibility: hidden;
  user-select: none;
  border-radius: 100%;
  border: #00ffff 2px solid;
  box-shadow: 0 0 5px black;
  width: 50px;
  height: 50px;
  background-image: radial-gradient(
    circle,
    rgba(0, 255, 255, 0.5534546582304797) 0%,
    rgba(255, 255, 255, 1) 60%
  );
  background-color: white;
  -webkit-transition: opacity 200ms ease-in-out;
  -moz-transition: opacity 200ms ease-in-out;
  -ms-transition: opacity 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  z-index: 1000000;
}
</style>
